$base-font-family: Ubuntu, sans-serif;
$heading-font-family: 'Zilla Slab', Georgia, 'Times New Roman', Times, serif;
$base-font-size: 16px;
$base-font-weight: 400;
$small-font-size: $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit: 30px;

$text-color: #383a3f;
$background-color: #f9fbff;
$brand-color: #c196c5;
$secondary-brand-color: #d1e3d5;
$reverse-text-color: $background-color;

$grey-color: #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark: darken($grey-color, 25%);

// Width of the content area
$content-width: 900px;

$on-palm: 600px;
$on-laptop: 800px;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import "base",
"layout",
"syntax-highlighting"
;

@media (prefers-color-scheme: dark) {
    @import "../_sass/manadark.scss";
}