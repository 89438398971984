/*
Theme Name: Mana with Tea
Theme URI: https://colettesnow.com
Author: Colette Snow
Author URI: https://colettesnow.com
Description: Mana with Tea theme
Version: 1.0
*/

body {
    font-family: $base-font-family;
    margin     : 0;
    font-size  : $base-font-size;
    background : $brand-color;
    border-top : solid 8px $brand-color;
    color      : $text-color;
}

a,
.disqus_comments .comment-policy .policy-link {
    color: $brand-color;
}

.wrap {
    background    : $background-color;
    font-size     : 0.833125em;
    padding-bottom: 10px;
}

header {
    padding: 10px 0 20px 0;
}

header .inner {
    display  : flex;
    flex-wrap: wrap;
}

.header {
    flex             : 1 1 30%;
    background       : url(https://images.manawithtea.com/logo.png);
    background-size  : auto 100px;
    background-repeat: no-repeat;
    margin-top       : 20px;
}

.header a {
    display    : block;
    height     : 110px;
    width      : 210px;
    text-indent: -3000px;
    direction  : ltr;
}

.header {
    width : 220px;
    height: 110px;
}

header nav {
    flex          : 1 4 65%;
    height        : 80px;
    vertical-align: middle;
    padding-top   : 40px;
    text-align    : center;
}

header nav a {
    text-decoration: none;
    color          : $text-color;
    font-weight    : bold;
    font-size      : 1.2em;
}

header nav a:hover {
    text-decoration: underline;
}

header nav ul,
footer ul {
    list-style: none;
    padding   : 0;
}

header nav li,
footer ul li {
    margin : 0;
    padding: 0 15px 0 0;
    display: inline-block;
}

header nav ul li:last-child {
    padding-right: 0;
}

.inner,
.breadcrumbs {
    max-width: $content-width;
    margin   : 0 auto;
}

.breadcrumbs {
    list-style: none;
    padding   : 0;

    li {
        padding: 0;
        display: inline-block;
    }

    li:after {
        content      : '\00BB';
        padding-right: 4px;
    }

    li:last-child:after {
        content: '';
    }
}

footer .copyright,
footer .powered {
    font-size: 0.75em;
}

footer .copyright {
    float: left;
}

footer .powered {
    float: right;
}

.main-area p,
.main-area li {
    line-height: 1.833125em;
}

.main .inner {
    display  : flex;
}

.posts {
    flex          : 4 1 65%;
    vertical-align: top;
}

h1 a {
    text-decoration: none;
}

.sidebar {
    flex          : 1 1 210px;
    vertical-align: top;
    padding-left  : 30px;
    min-width     : 210px;
    max-width     : 210px;
}

@mixin heading-style {
    color      : $brand-color;
    font-family: $heading-font-family;
    clear: both;
}

h1 {
    @include heading-style;
    font-size: 1.7em;
}

h2 {
    @include heading-style;
    font-size: 1.5em;
}

h3 {
    @include heading-style;
    font-size: 1.4em;
}

h4 {
    @include heading-style;
    font-size: 1.3em;
}

h5 {
    @include heading-style;
    font-size: 1.2em;
}

h6 {
    @include heading-style;
    font-size: 1.1em;
}

.footer_links .footer-section-heading {
    color: $text-color;
}

img.alignright {
    float : right;
    margin: 0 0 1em 1em;
}

img.alignleft {
    float : left;
    margin: 0 1em 1em 0;
}

img.aligncenter {
    display     : block;
    margin-left : auto;
    margin-right: auto;
}

.alignright {
    float: right;
}

.alignleft {
    float: left;
}

.aligncenter {
    display     : block;
    margin-left : auto;
    margin-right: auto;
}

/* Comments */

.commentlist {
    list-style: none;
    padding   : 0;
}

.commentlist li {
    margin-bottom: 10px;
}

.commentlist cite {
    font-style: normal;
}

.commentlist .avatar {
    vertical-align: bottom;
    margin-bottom : -15px;
    margin-right  : 10px;
}

.commentmetadata {
    margin-left: 45px;
    font-size  : 0.75em;
}

.commentmetadata a {
    text-decoration: none;
}

.commentmetadata .comment-edit-link {
    text-decoration: underline;
}

.breadcrumbs {
    font-size: 0.9em;
}

.sidebar ul,
.footer_links ul {
    padding: 0 0 0 20px;
}

.sidebar li,
.footer_links li {
    padding: 2px 0 2px 0;
}

footer a {
    color: $background-color;
}

footer {
    color: $background-color;
}

.footer_links {
    background: $secondary-brand-color;

}

.footer_links .inner {
    display  : flex;
    flex-wrap: wrap;
}

.footer_links a {
    color: $text-color;
}

.footer_links .column {
    flex          : 1 1 30%;
    vertical-align: top;
    margin-right  : 30px;
}

.footer_links .column:last-child {
    margin-right: 0;
}

.footer_links .inner .column .footer-section-heading {
    font-size: 1.4em;
}

.footer_links ul {
    list-style  : none;
    padding-left: 0;
}

.posts footer {
    color: $text-color;
}

.posts footer a {
    color: $brand-color;
}

.grid-intro {
    margin-top              : 20px;
    display                 : grid;
    gap                     : 10px;
    // grid-template-columns: 290px 290px 290px;
    color                   : $text-color;
    height                  : 450px;
}

.box {
    position: relative;
}

.box .categories {
    position: absolute;
    top     : 0;
    margin  : 15px -5px;
}

.box .category {
    font-size       : 0.75em;
    background-color: rgb(255, 255, 154);
    padding         : 5px;
    display         : inline-block;
    margin-left     : 5px;
    box-shadow      : 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.box .category {
    text-transform : uppercase;
    text-decoration: none;
    color          : #000;
}

.grid-intro .box .post_title {
    position        : absolute;
    bottom          : 0;
    width           : 100%;
    background-image: linear-gradient(rgba(2, 2, 2, 0.01), rgba(5, 5, 5, .9));
    padding         : 0;
    margin          : 0 -20px;
    font-size       : 0.99em;
    color           : $reverse-text-color;
}

.post_title a {
    color          : $reverse-text-color;
    text-decoration: none;
    padding        : 10px 20px;
    display        : block;
    font-weight    : bold;
}

.box {
    background-color   : $grey-color-dark;
    color              : $reverse-text-color;
    border-radius      : 5px;
    padding            : 20px;
    font-size          : 150%;
    background-size    : cover;
    background-position: center;
}

.featured-1 {
    grid-column: 1 / span 2;
}

.featured-2 {
    grid-column: 3;
    grid-row   : 1 / span 2;
}

.featured-3 {
    grid-column: 1;
    grid-row   : 2;
}

.featured-4 {
    grid-column: 2;
    grid-row   : 2;
}

.post-index article {
    display: flex;

    .thumbnail {
        flex: 1 1 150px;
    }

    .post_details {
        flex: 4 1 auto;
    }
}

.main-area-full {
    width : 100%;
    height: 490px;
}

article {
    margin-bottom: 20px;
}

.thumbnail,
.post_details {
    vertical-align: top;
}

.thumbnail {
    min-height: 150px;
    min-width : 150px;
    max-width : 150px;
}

.post_details {
    padding: 0 0 0 20px;
}

.post_details .entry-title a {
    text-decoration: none;
    &:hover { text-decoration: underline;}
}

.post_details .post_meta,
.post_meta_aside {
    font-size  : 0.9em;
    line-height: 18px;
}

.disqus_comments {
    margin-bottom: 20px;
}

dt {
    margin: 4px 0;
}

dd {
    margin: 4px 0;
}

.box .category-tag-reviews {
    background-color: #E7FFDF;
}

.box .category-tag-games {
    background-color: #BBEBFF;
}

.box .category-tag-anime {
    background-color: #FFDFF8;
}

.box .category-tag-thoughts {
    background-color: rgb(138, 140, 255);
}

article footer {
    clear: both;
}

.sidebar p {
    line-height: 1.375em;
}

.pagination {
    margin-bottom: 10px;
    display      : flex;
    line-height  : 20px;
}

.previous {
    text-align: left;
    flex      : 3 1;
}

.next {
    text-align: right;
    flex      : 3 1;
}

.page_number {
    text-align: center;
    flex      : 1 1;
    display   : block;
    width     : auto;
}

.user_profile dt {
    font-weight: bold;
}

.user_profile dd {
    margin-bottom: 5px;
}

/* Text meant only for screen readers. */
.screen-reader-text {
    border   : 0;
    clip     : rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height   : 1px;
    margin   : -1px;
    overflow : hidden;
    padding  : 0;
    position : absolute;
    width    : 1px;
    word-wrap: normal;
}

.grid-intro {
    margin-bottom: 20px;
}

.posts img {
    max-width: 100%;
    margin   : 0 auto;
    display  : block;
}

.post-gallery .gallery-img {
    display: inline-block;
    padding: 2px;
}

.post .thumbnail picture img,
.post .thumbnail img {
    border-radius: 5px;
}

@media only screen and (min-width: 600px) and (max-width: $content-width) {
    .main {
        padding   : 0 20px;
        flex-basis: 50%;
    }

    .sidebar {
        padding-left: 30px;
        flex        : 1 1 100%;
    }
}

.yt {
    width: 100%;
}

@media only screen and (max-width: 599px) {

    body {
        width: 100%;
    }

    .wrap {
        width: 100%;
    }

    .main-area-full {
        display: none;
    }

    .main-area {
        width         : 95%;
        padding-bottom: 10px;
        padding       : 0 10px;
    }

    .main-area article,
    .post_details,
    .breadcrumbs {
        width  : 100%;
        margin : 0 auto;
        padding: 10px 0 0 0;
    }

    .main-area .thumbnail {
        display: none;
    }

    header {
        width: 100%;
    }

    header .header {
        text-align         : center;
        width              : 100%;
        background-position: center top;
    }

    .main-area img {
        max-width: 100%;
        margin   : 0 auto;
    }

    header nav {
        display   : block;
        margin    : 0;
        padding   : 0;
        width     : 100%;
        text-align: center;
        min-width : unset;
        height    : unset;
    }

    header nav li {
        display    : inline;
        line-height: 2.5em;
    }

    .sidebar {
        display: none;
    }

    .wrap {
        padding-bottom: 20px;
    }

    .footer_links .inner {
        display: block;
    }

    .footer_links .column,
    footer {
        display: block;
        padding: 10px 10px;
    }

    .footer_links .footer-section-heading {
        margin-top : 0;
        padding-top: 6px;
    }

    footer .menu-footer-container {
        text-align : center;
        line-height: 2em;
    }

    .inner {
        width    : unset;
        max-width: unset;
    }

    .copyright p {
        font-size : 11pt;
        text-align: center;
    }

    .post_details .post_meta,
    .post_meta_aside,
    .breadcrumbs {
        font-size  : 0.95em;
        line-height: 18px;
    }

    footer .powered {
        text-align: center;
        float     : unset;
    }

    .pagination {
        margin-top: 20px;
    }

}

.top_ad {
    margin    : 0 auto 30px auto;
    text-align: center;
    max-width : 900px;
    min-height: 90px;
}

.post-type-link {
    .post_details {
        padding-left: 0;
        width       : 100%;
    }
}

blockquote {
    border-left : 2px solid $brand-color;
    padding-left: 10px;
    margin-left : 2px;
}

.grid-intro {

    .featured-2 .categories,
    .featured-3 .categories,
    .featured-4 .categories {

        a:nth-child(3),
        a:nth-child(4),
        a:nth-child(5) {
            margin-top: 10px;
            display: block;
            width: max-content;
        }
    }
}
